import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { Sodas } from 'src/dto/Sodas';
import makeModelViewerUtils from 'src/util/viewer.util';

const ModelViewerContext = createContext<
  ReturnType<typeof makeModelViewerUtils>
>({});
export default ModelViewerContext;

export function ModelViewerProvider(props: PropsWithChildren<{ obj?: Sodas }>) {
  const [modelViewer, setModelViewer] = useState<any>();
  const [mvRef, setMvRef] = useState({ current: undefined, lastUpdate: 0 });

  useEffect(() => {
    setMvRef({ current: modelViewer, lastUpdate: Date.now() });

    const handleModelLoad = () => {
      setMvRef({ current: modelViewer, lastUpdate: Date.now() });
    };

    if (modelViewer) {
      modelViewer.addEventListener('load', handleModelLoad);
    }

    return () => {
      if (modelViewer) {
        modelViewer.removeEventListener('load', handleModelLoad);
      }
    };
  }, [modelViewer]);

  const [utils, setUtils] = useState<ReturnType<typeof makeModelViewerUtils>>(
    {}
  );

  useEffect(() => {
    setUtils(makeModelViewerUtils(modelViewer));
  }, [modelViewer]);

  useEffect(() => {
    if (utils.setObject && props.obj) {
      utils.setObject(props.obj.model);
    }
  }, [props.obj, utils]);

  useEffect(() => {
    // From https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    const onResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <>
      <Helmet>
        <script
          type="module"
          src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"
        />
      </Helmet>
      <div className="relative w-full full-screen">
        {/* @ts-expect-error */}
        <model-viewer
          ref={(element: any) => {
            setModelViewer(element);
          }}
          class="w-full h-full bg-gray-900"
          camera-controls
          auto-rotate
          environment-image="/skybox.hdr"
        />

        <ModelViewerContext.Provider value={{ ...mvRef, ...utils }}>
          {props.children}
        </ModelViewerContext.Provider>
      </div>
    </>
  );
}

export const useModelViewer = () => useContext(ModelViewerContext);
