import React from 'react';
import { CgChevronDoubleUp } from 'react-icons/cg';

const ArrowNavigation = (props: {
  currentIdx: number;
  objCount: number;
  onNavigate: (nextIdx: number) => void;
}) => {
  return (
    <>
      <CgChevronDoubleUp
        className="absolute text-white cursor-pointer bottom-1/2 left-4 md:left-8 -rotate-90 transform"
        onClick={() => {
          props.onNavigate(
            (props.objCount + props.currentIdx - 1) % props.objCount
          );
        }}
        size="4rem"
      />
      <CgChevronDoubleUp
        className="absolute text-white cursor-pointer bottom-1/2 right-4 md:right-8 rotate-90 transform"
        size="4rem"
        onClick={() => {
          props.onNavigate(
            (props.objCount + props.currentIdx + 1) % props.objCount
          );
        }}
      />
    </>
  );
};

export default ArrowNavigation;
