import React from 'react';
import { Helmet } from 'react-helmet';
import ModelViewer from 'src/components/ModelViewer';

const title = 'Virtuali Sodų Galerija';
const description =
  'Lietuviški šiaudiniai sodai. Tai sakralinę prasmę turintis mūsų protėvių palikimas, jų pasaulio pajauta, užrašyta ne žodžiais, o geometrinėmis figūromis.';
const image = '';

const IndexPage = () => {
  return (
    <>
      <Helmet>
        {/* <!-- COMMON TAGS --> */}
        <meta charSet="utf-8" />
        <title>{title}</title>
        {/* <!-- Search Engine --> */}
        <meta name="description" content={description} />
        <meta name="image" content="/android-icon-192x192.png" />
        {/* <!-- Schema.org for Google --> */}
        <meta itemProp="name" content={title} />
        <meta itemProp="description" content={description} />
        <meta itemProp="image" content="/android-icon-192x192.png" />
        {/* <!-- Twitter --> */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {/* <!-- Open Graph general (Facebook, Pinterest & Google+) --> */}
        <meta name="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta name="og:image" content="/og_sodas.png" />
        <meta name="og:url" content="https://galerija.sodai.eu/" />
        <meta name="og:site_name" content={title} />
        <meta name="og:locale" content="lt_LT" />
        <meta name="og:locale:alternate" content="en_US" />
        <meta name="og:type" content="website" />
      </Helmet>
      <ModelViewer />
    </>
  );
};

export default IndexPage;
