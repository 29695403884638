import React from 'react';
import { TiArrowBack } from 'react-icons/ti';

export default function RedirectHome(props: { homeUrl: string }) {
  return (
    <button
      className="absolute text-3xl text-white top-4 left-4"
      onClick={() => {
        location.href = props.homeUrl;
      }}
    >
      <TiArrowBack title="Grįžti į pradžią / Return home" />
    </button>
  );
}
