import { Model } from 'src/dto/Sodas';

const setObject = (modelViewer: any, model: Model) => {
  modelViewer.src = model.src;

  const target = model.camera?.target;
  modelViewer.cameraTarget = `${target?.x ?? 'auto'} ${target?.y ?? 'auto'} ${
    target?.z ?? 'auto'
  }`;

  const minOrbit = model.camera?.orbit?.min;
  modelViewer.minCameraOrbit = `auto ${minOrbit?.phi ?? '0deg'} ${
    minOrbit?.zoom ?? 'auto'
  }`;

  const maxOrbit = model.camera?.orbit?.max;
  modelViewer.maxCameraOrbit = `auto ${maxOrbit?.phi ?? '180deg'} ${
    maxOrbit?.zoom ?? 'auto'
  }`;

  const defaultOrbit = model.camera?.orbit?.default;
  modelViewer.cameraOrbit = `${defaultOrbit?.x ?? 'auto'} ${
    defaultOrbit?.y ?? 'auto'
  } ${defaultOrbit?.zoom ?? 'auto'}`;
};

export default function makeModelViewerUtils(modelViewer: any) {
  if (!modelViewer) {
    return {};
  }

  return {
    current: modelViewer,
    setObject: (model: Model) => setObject(modelViewer, model),
  };
}
