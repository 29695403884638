import React, { useEffect, useRef, useState } from 'react';
import Card from './Card';
import { CgChevronDoubleUp } from 'react-icons/cg';
import { Sodas } from 'src/dto/Sodas';

const ItemDrawer = (props: {
  list: Sodas[];
  activeItem?: Sodas;
  onItemClick?: (obj: Sodas) => void;
}) => {
  const [open, setOpen] = useState(false);

  const handleModelClick = (el: Sodas) => {
    if (props.onItemClick) {
      props.onItemClick(el);
    }

    setOpen(false);
  };

  return (
    <div
      className="fixed bottom-0 z-10 w-full bg-gray-800"
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <div
        className="bg-gray-700 cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <span className="text-xs text-white">
          <CgChevronDoubleUp
            className="inline-block ml-1 align-text-bottom"
            size={16}
            style={{ transform: open ? 'rotate(180deg)' : undefined }}
          />
          Rodyti visus / Show all
        </span>
      </div>
      <hr />
      <div
        className="transition-all duration-200"
        style={{ maxHeight: open ? '18rem' : 0 }}
      >
        <div
          className="w-full py-1 overflow-x-auto"
          onWheel={e => {
            // Support for vertical mouse wheels.
            const el = e.currentTarget;
            el.scrollTo({ left: el.scrollLeft + e.deltaY });
          }}
        >
          <div className="h-full w-max">
            {props.list.map((e, i) => (
              <Card
                className="inline-block w-48 mx-1 lg:w-64"
                active={e === props.activeItem}
                objInfo={e.metadata}
                key={i}
                onModelClick={() => handleModelClick(e)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemDrawer;
