import React from 'react';
import { Metadata } from 'src/dto/Sodas';

const Card = (
  props: React.HTMLAttributes<HTMLDivElement> & {
    objInfo: Metadata;
    active?: boolean;
    onModelClick?: () => void;
    onAuthorClick?: () => void;
  }
) => {
  const { objInfo, active, onModelClick, onAuthorClick, ...divProps } = props;

  return (
    <div {...divProps}>
      <article
        className={`overflow-hidden border ${
          active ? 'border-gray-400' : 'border-gray-700'
        } border-solid rounded-lg`}
      >
        <img
          alt="Placeholder"
          className="block object-cover w-full h-40 cursor-pointer"
          src={objInfo.thumbnail.src}
          onClick={onModelClick}
        />
        <header className="flex items-center justify-between p-2 leading-tight">
          <h1 className="w-full h-4 text-lg text-center">
            <span
              className="text-white no-underline cursor-pointer hover:underline"
              onClick={onModelClick}
            >
              {objInfo.title}
            </span>
          </h1>
        </header>
        <footer className="h-10 p-2">
          <span
            className="flex items-center text-white cursor-pointer hover:underline"
            onClick={onAuthorClick ?? onModelClick}
          >
            <p className="ml-2 text-sm">{objInfo.author}</p>
          </span>
        </footer>
      </article>
    </div>
  );
};

export default Card;
