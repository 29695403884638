import React, { useEffect, useState } from 'react';
import { FiVolumeX, FiVolume2 } from 'react-icons/fi';

export default function AudioControls(props: { tracks: string[] }) {
  const [currentTrackId, setCurrentTrackId] = useState(0);
  const [playing, setPlaying] = useState(false);

  const [audio, setAudio] = useState<HTMLAudioElement>();

  useEffect(() => {
    const audio = new Audio(props.tracks[currentTrackId]);
    audio.onended = () => {
      setCurrentTrackId(id => (id + 1) % props.tracks.length);
    };

    audio.onpause = () => setPlaying(false);
    audio.onplaying = () => setPlaying(true);

    audio
      .play()
      .catch
      // We expect an error here, as audio works only after interacting on the page.
      // On initial load this will fail.
      // On subsequent loads (when track no. changes) this will work as intended.
      ();

    setAudio(audio);
  }, [currentTrackId, props.tracks]);

  return audio ? (
    <button
      className="absolute text-3xl text-white top-4 right-4"
      onClick={() => {
        playing ? audio.pause() : audio.play();
      }}
    >
      {playing ? <FiVolume2 /> : <FiVolumeX />}
    </button>
  ) : null;
}
