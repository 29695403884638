import React, { useEffect, useState } from 'react';
import { ModelViewerProvider } from 'src/contexts/ModelViewerContext';
import { DataDoc, Sodas } from 'src/dto/Sodas';
import ArrowNavigation from './ArrowNavigation';
import AudioControls from './AudioControls';
import ItemDrawer from './ItemDrawer';
import RedirectHome from './RedirectHome';

const ModelViewer = () => {
  const [data, setData] = useState<DataDoc>();
  const [currentObj, setCurrentObj] = useState<Sodas>();
  const [currentObjIdx, setCurrentObjIdx] = useState<number>();

  useEffect(() => {
    fetch('/_data.json')
      .then(r => r.json() as Promise<DataDoc>)
      .then(r => setData(r));
  }, []);

  useEffect(() => {
    if (data?.objects && !currentObj) {
      setCurrentObj(data.objects[0]);
    }
  }, [currentObj, data]);

  useEffect(() => {
    setCurrentObjIdx(data?.objects.findIndex(o => o === currentObj));
  }, [currentObj, data]);

  return (
    <ModelViewerProvider obj={currentObj}>
      <ItemDrawer
        list={data?.objects ?? []}
        activeItem={currentObj}
        onItemClick={obj => {
          setCurrentObj(obj);
        }}
      />
      {typeof currentObjIdx === 'number' && (
        <ArrowNavigation
          currentIdx={currentObjIdx}
          objCount={data?.objects.length ?? 0}
          onNavigate={nextIdx => {
            setCurrentObj(data?.objects[nextIdx]);
          }}
        />
      )}
      <div className="absolute text-xl text-white bottom-10 right-4">
        {currentObj?.metadata?.title} - {currentObj?.metadata?.author}
      </div>
      <AudioControls tracks={data?.audio ?? []} />
      <RedirectHome homeUrl={data?.home_url ?? '/'} />
    </ModelViewerProvider>
  );
};

export default ModelViewer;
